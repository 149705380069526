import React, { Fragment} from "react";
import classnames from 'classnames';
import $ from "jquery";
import { domain } from "../../../utils";

// export const Tutorial = ({ tutorial, title }) => {


export class Tutorial extends React.Component {

  componentDidMount() {
    window.scrollTo({
      top: 0,
    });
    $(document).ready( function() {
      var timeline_block = $('.cd-timeline-block');
    
      //hide timeline blocks which are outside the viewport
      timeline_block.each(function(){
        if($(this).offset().top > $(window).scrollTop()+$(window).height()*0.75) {
          $(this).find('.cd-timeline-badge').addClass('is-hidden');
        }
      });
    
      //on scolling, show/animate timeline blocks when enter the viewport
      $(window).on('scroll', function(){
        timeline_block.each(function(){
          if( $(this).offset().top <= $(window).scrollTop()+$(window).height()*0.35 && $(this).find('.cd-timeline-badge').hasClass('is-hidden') ) {
            $(this).find('.cd-timeline-badge').removeClass('is-hidden').addClass('bounce-in');
          }
        });
      });
    });
  }

  render() {
    const {title, tutorial} = this.props;
    return  (
      <Fragment>
        <section
          className="section-home position-relative section-functions"
          id="section_functions"
        >
          <div className="container-fluid">
            <div className="row mt-2 mb-5">
              <div className="col-md-10 offset-md-1 col-12">
                {title && (
                  <div className="title-small border-blue mb-2 mb-md-5">
                    <h2 className="text-uppercase">{title}</h2>
                  </div>
                )}
              </div>
            </div>
            <div className="row" id="cd-timeline">
              <div className="col-md-10 offset-md-1 col-12">
                {tutorial.map(
                  ({heading, bigHeading, description, image }, index) => {
                    return (
                      <div key={index} className={classnames('cd-timeline-block row position-relative', index === 0 ? 'pb-md-5' : 'py-3 py-md-5')}>
                        <div
                          className={`text-center col-12 col-md-4 col-lg-4${
                            index % 2 === 0
                              ? ""
                              : " order-md-2 float-right offset-lg-2"
                          }`}
                        >
                          <img
                            className="mw-100"
                            alt={heading}
                            src={`${domain}${image.url}`}
                          />
                        </div>
                        <div className="cd-timeline-badge">
                          <span>
                            {index + 1}
                          </span>
                        </div>
                        <div className="cd-timeline-hide"/>
                       
                        <div
                          className={`col-12 col-md-8 col-lg-5 ${
                            index % 2 === 0
                              ? "float-right my-auto pl-md-4 offset-lg-3"
                              : "order-md-1 my-auto pr-md-4"
                          }`}
                        >
                          <div className="small">
                            <small>{index + 1}. {heading}</small>
                          </div>
                          <h4 className="h1">{bigHeading}</h4>
                          <p className="md-2 text-left">{description}</p>
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <a href={`features/`} className="btn btn-warning text-white small text-uppercase">
                  <b>More Features</b>
                </a>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}
