import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { Subscription } from "../components/partials/subscription/subscription"
import { Blog } from "../components/partials/blog/blog"
import { Faq } from "../components/partials/faq/faq"
import { Banner } from "./../components/partials/banner/banner"
import { UserProblems } from "../components/partials/userProblems/userProblems"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import { Tutorial } from "../components/partials/tutorial/tutorial"

const IndexPage = ({ data }) => {
  const {
    meta,
    language,
    header,
    footer,
    firstSection,
    blogHeading,
    userProblemHeading,
    userProblems,
    tutorialHeading,
    tutorial,
    subscriptionHeading,
    subscriptionDescription,
    faqHeading,
    faqItems,
  } = data.strapi.homepage;
  const {blogitems} = data.strapi;
  
  const dispatch = useContext(GlobalDispatchContext)
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language })
  })

  return (
    <Layout header={header} footer={footer}>
      <SEO title={meta.title} description={meta.description} />
      <Banner banner={firstSection} />
      {blogitems && blogitems.length > 0 && (
        <div>
          <Blog blogHeading={blogHeading} blogItems={blogitems} />
        </div>
      )}
      {userProblemHeading !== null &&
        userProblemHeading.length > 0 &&
        userProblems !== null &&
        userProblems.length > 0 && (
          <UserProblems
            userProblemHeading={userProblemHeading}
            userProblems={userProblems}
          />
        )}
      <Tutorial
        title={tutorialHeading}
        tutorial={tutorial}
      />
      {subscriptionHeading !== null &&
        subscriptionHeading.length > 0 &&
        subscriptionDescription !== null &&
        subscriptionDescription.length > 0 && (
          <Subscription
            subscriptionHeading={subscriptionHeading}
            subscriptionDescription={subscriptionDescription}
          />
        )}
      {faqHeading !== null &&
        faqHeading.length > 0 &&
        faqItems !== null &&
        faqItems.length > 0 && (
          <Faq faqHeading={faqHeading} faqItems={faqItems} />
        )}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Homepage($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      homepage(id: $id) {
        meta {
          title
          description
        }
        language
        header {
          logo {
            url
          }
          links {
            label
            slug
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
        firstSection {
          title
          description
          media {
            url
          }
        }
        blogHeading
        userProblemHeading
        userProblems {
          title
          description
          img {
            url
          }
        }
        tutorialHeading
        tutorial {
          heading
          bigHeading
          description
          image {
            url
          }
        }
        subscriptionHeading
        subscriptionDescription

        faqHeading
        faqItems {
          question
          answer
        }
      }
      blogitems(sort: "publishing_date:DESC", where: $language) {
        slug
        language
        title
        publishing_date
        image {
          url
        }    
      }
    }
  }
`
