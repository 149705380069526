import React, { Fragment } from "react"
import { domain } from "../../../utils"

export const UserProblems = ({ userProblemHeading, userProblems }) => {
  return (
    <Fragment>
      <section className="partial-user-problems section-home position-relative">
        <div className="container-fluid t-cell-middle">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <div className="title-small border-blue mb-2 mb-md-5" >
                <h2 className="text-uppercase">{userProblemHeading}</h2>
              </div>
              <div className="container-fluid p-0">
                <div className="partial-user-problems-list row mt-3 mt-md-4">
                  {userProblems.map(({ title, description, img }, index) => {
                    return (
                      <div
                        key={index}
                        className="col-12 col-md-6  "
                        data-aos="fade-up"
                        data-aos-once="true"
                      >
                        <div className="container-fluid p-0">
                          <div className="row mt-md-1">
                            <div className="col-2 pr-0 pl-sm-4 pr-sm-2 mb-sm-3 px-md-2 px-lg-2 px-xl-3">
                              <div>
                                <img
                                  className="mw-100"
                                  src={`${domain}${img.url}`}
                                  alt={title}
                                />
                              </div>
                            </div>
                            <div className="col-10 col-md-9 mb-md-5">
                              <div className="d-inline text-left">
                                <div className="font-weight-bold">{title}</div>
                                <p>{description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
