import React, { Fragment } from "react"
import { Link } from "gatsby"
import { globalHistory } from "@reach/router"

export const Subscription = ({
  subscriptionHeading,
  subscriptionDescription,
}) => {
  if (!subscriptionHeading || !subscriptionDescription) { return <></>; }
  const { location } = globalHistory
  const language = location.pathname.split("/").slice(1, 2)[0]

  return (
    <Fragment>
      <section
        className="section-home position-relative section-features"
        id="section_coopz_features"
      >
        <div className="container-fluid t-cell-middle bg-primary">
          <div className="row">
            <div className="col-12">
              <div className="container p-0">
                <div className="row my-2 my-md-5">
                  <div
                    className="col-12 text-center"
                    data-aos="fade-up"
                    data-aos-once="true"
                  >
                    <h2 className="text-white">{subscriptionHeading}</h2>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-12 text-center features"
                    data-aos="fade-up"
                    data-aos-once="true"
                  >
                    <form
                      className="input-group input-group-features mb-1 mb-md-3 w-100 w-md-50 mx-auto subscription-form"
                      method="POST"
                      action="https://coopz.us5.list-manage.com/subscribe/post?u=b085b4424596b17706957b1d8&amp;amp;id=f801c81c07"
                    >
                      <input
                        type="hidden"
                        name="u"
                        value="b085b4424596b17706957b1d8"
                      />
                      <input type="hidden" name="id" value="f801c81c07" />
                      <input
                        className="form-control bg-transparent text-white border-left-rounded"
                        name="EMAIL"
                        id="mce-EMAIL"
                        type="text"
                        placeholder="Email"
                        aria-describedby="sign-up"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn text-primary bg-white font-weight-bold px-5"
                          type="submit"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                        >
                          Sign up
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-12 text-center text-small"
                    data-aos="fade-up"
                    data-aos-once="true"
                  >
                    <p className="d-inline text-white op-7 m-0 mr-1">
                      {subscriptionDescription}
                    </p>
                    <Link
                      to={`/${language}/${language === 'en' ? 'privacy-policy' : 'datenschutz'}/`}
                      className="text-white d-inline m-0"
                    >
                      {language === 'en' ? ('Privacy Policy') : ('Datenschutz')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
