import React, { Fragment } from "react"
import { generateId } from "../../../utils"

export const FaqItem = ({ faqItems }) => {
  const id = generateId()

  return (
    <Fragment>
      <div className="w-100" id={`accordion-${id}`}>
        {faqItems.map((item, index) => {
          const firstItem = index === 0

          return (
            <div
              key={index}
              className="accordion light-shadow-down my-3"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div
                className="accordion-header p-2 accordion-top border-bottom"
                id={`headingkat${id}${index + 1}`}
              >
                <button
                  className={`${
                    firstItem ? "" : "collapsed"
                  } text-left w-100 d-flex justify-content-between align-items-center`}
                  data-toggle="collapse"
                  data-target={`#collapse${id}${index + 1}`}
                  aria-expanded={firstItem ? true : false}
                  aria-controls={`collapse${id}${index + 1}`}
                >
                  <div className="accordion-button-title small">
                    <h5 className="my-1">{item.question}</h5>
                  </div>
                  <i className="fa fa-angle-up"></i>
                </button>
              </div>
              <div
                className={`${
                  firstItem ? "show" : ""
                } collapse accordion-bottom`}
                id={`collapse${id}${index + 1}`}
                aria-labelledby={`headingkat${id}${index + 1}`}
                data-parent={`#accordion-${id}`}
              >
                <div className="accordion-body">
                  <p className="m-0">{item.answer}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}
