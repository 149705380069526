import React, { Fragment } from "react"
import { FaqItem } from "./faqItem"

export const Faq = ({ faqHeading, faqItems }) => {
  if (!faqHeading || !faqItems) { return <></>; }

  return (
    <Fragment>
      <section className="section-home position-relative section-faq">
        <div className="container-fluid t-cell-middle">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <div className="title-small border-blue mb-2 mb-md-5">
                <h2 className="text-uppercase">{faqHeading}</h2>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <FaqItem faqItems={faqItems} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
